import React, { useRef, useState } from "react";
import Home1 from "../Assets/home1.png";
import "./Careers.css";

const Careers = () => {

return(
<div className="careers-wrapper">
  <div className="careers-image-container">
    <img src={Home1} alt="careers" className="careers-background-image" />
    <h1 className="careers-heading">Careers</h1>
  </div>
  <div className="coming-soon-container">
        <h2 className="coming-soon-text">"COMING SOON"</h2>
      </div>
  </div>
  )

}

export default Careers;