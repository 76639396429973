import React from "react";
import Home1 from "../Assets/home1.png";
import "./News.css";

const News = () => {
  return (
    <div className="news-wrapper">
      <div className="news-image-container">
        <img src={Home1} alt="news" className="news-background-image" />
        <h1 className="news-heading">News & Media</h1>
      </div>
      <div className="coming-soon-container">
        <h2 className="coming-soon-text">"COMING SOON"</h2>
      </div>
    </div>
  );
};

export default News;
