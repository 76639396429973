import React, { useState } from "react";
import Home1 from "../Assets/home1.png";
import phone from "../Assets/ph.png";
import location from "../Assets/loc.png";
import mail from "../Assets/mail1.png";
import "./Contact.css";

const countryCodes = [
  { code: "+1", country: "United States" },
  { code: "+44", country: "United Kingdom" },
  { code: "+61", country: "Australia" },
  { code: "+81", country: "Japan" },
  { code: "+86", country: "China" },
  { code: "+33", country: "France" },
  { code: "+39", country: "Italy" },
  { code: "+49", country: "Germany" },
  // India +91 is already set as default, so it's not in this list
];

const Contact = () => {
  const [othersInput, setOthersInput] = useState("");
  const [showSuccessMessage, setshowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    role: "",
    message: "",
    countryCode: "+91", // Setting India +91 as default
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "role") {
      if (value === "Others") {
        setOthersInput("");
        setError((prev) => ({ ...prev, role: "" }));
      }
    }
  };

  const handleOthersInputChange = (e) => {
    setOthersInput(e.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!/^\d{10}$/.test(formData.phone))
      errors.phone = "Phone number must be 10 digits.";
    if (!/^\S+@\S+\.\S+$/.test(formData.email))
      errors.email = "Invalid email address.";
    if (!formData.role) errors.role = "Please select an option.";
    if (formData.role === "Others" && !othersInput)
      errors.others = "Please specify.";
    if (!formData.message) errors.message = "Message is required.";
    if (formData.message.length > 250)
      errors.messageLength = "Message can't exceed 250 characters.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted:", formData, othersInput);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
        message: formData.message,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch("/handle-contact-us.php", requestOptions)
        .then((resp) => resp.json())
        .then((jsonResp) => {
          console.log(jsonResp);
          if (jsonResp && jsonResp.status === "success") {
            setshowSuccessMessage(true);
          }
        })
        .finally(() => {
          setFormData({
            name: "",
            phone: "",
            email: "",
            role: "",
            message: "",
            countryCode: "+91", // Reset default to India
          });
          setOthersInput("");
        });
    }
  };

  return (
    <div className="contact-wrapper">
      <div className="contact-image-container">
        <img src={Home1} alt="about" className="contact-background-image" />
        <h1 className="contact-heading">Get in Touch with Us</h1>
        <h2 className="contact-subheading">
          We are here to help you elevate your brand with the best Mobile
          Digital Out Of Home Advertising Solutions. Whether you have questions,
          need assistance, or want to discuss your advertising needs, we would
          love to hear from you!
        </h2>
      </div>

      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-item">
            <img src={phone} alt="phone" className="contact-icon" />
            <div>
              <h3>Phone</h3>
              <p>+917338562232</p>
            </div>
          </div>
          <div className="contact-item">
            <img src={mail} alt="mail" className="contact-icon" />
            <div>
              <h3>Email</h3>
              <p>info@adeiaz.com</p>
            </div>
          </div>
          <div className="contact-item">
            <img
              src={location}
              alt="location"
              className="contact-address-icon"
            />
            <a
              href="https://www.google.com/maps/place/Dhi+Innovations/@12.8858509,77.594443,17z/data=!4m6!3m5!1s0x3bae152fd1dbd661:0xb4e926a02cb3fbc9!8m2!3d12.8858457!4d77.5970179!16s%2Fg%2F1hm5tqfxc?entry=ttu&g_ep=EgoyMDI0MTAwOC4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
              className="address-link"
            >
              <div>
                <h3 className="contact-address">Location</h3>
                <p className="contact-address">
                  DigiAdeiaz Media Pvt. Ltd, #630, DHI Innovation Park, Arekere,
                  Bannerghatta Road, Bangalore, Karnataka - 560076
                </p>
              </div>
            </a>
          </div>
        </div>

        <form className="contact-form" onSubmit={handleSubmit}>
          {showSuccessMessage && (
            <div className="mb-3">
              <p className="success-message">
                Thank you for your interest! Your notification has been
                forwarded to our dedicated team and they will be reaching out to
                you shortly. We appreciate your interest and look forward to
                connecting soon!
              </p>
            </div>
          )}
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />

          {error.name && <p className="error">{error.name}</p>}

          <div className="phone-container">
            <select
              className="code"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              style={{ width: "140px" }}
            >
              <option value="+91">India (+91)</option>
              {countryCodes.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.country} ({code.code})
                </option>
              ))}
            </select>

            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          {error.phone && <p className="error">{error.phone}</p>}

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />

          {error.email && <p className="error">{error.email}</p>}

          <select name="role" value={formData.role} onChange={handleChange}>
            <option value="">Who are you?</option>
            <option value="Advertiser (media agency)">
              Advertiser (media agency)
            </option>
            <option value="Advertiser (brand)">Advertiser (brand)</option>
            <option value="Taxi owner">Taxi owner</option>
            <option value="Interested Investor">Interested Investor</option>
            <option value="Join the team">Join Us</option>
            <option value="Others">Others</option>
          </select>

          {error.role && <p className="error">{error.role}</p>}

          {formData.role === "Others" && (
            <input
              type="text"
              placeholder="Please specify"
              value={othersInput}
              onChange={handleOthersInputChange}
              className={error.others ? "error-input" : ""}
            />
          )}

          {error.others && <p className="error">{error.others}</p>}

          <textarea
            className="message-box"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            maxLength={250}
            style={{ height: "150px" }}
          />

          {error.message && <p className="error">{error.message}</p>}
          {error.messageLength && (
            <p className="error">{error.messageLength}</p>
          )}

          <button type="submit" className="send-button">
            SEND A MESSAGE
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
