import { useEffect, useState } from 'react'
export default () => {
    const [showCookieConsent, setshowCookieConsent] = useState(true)
    const handleAccept = () => {
        document.cookie = "cookieconsent=true"
        setshowCookieConsent(false)
    }
    useEffect(() => {
        if (document.cookie) {
            let cookie = document.cookie;
            if(cookie.indexOf(';') >= 0) {
                const cookieMap = {};
                cookie.split(';').forEach(x => {
                    const [name, val] = x.split('=');
                    cookieMap[name.trim()] = val.trim();
                })
                if(cookieMap['cookieconsent'] == 'true') {
                    setshowCookieConsent(false)
                }
            }
        }
    }, [])
    if (showCookieConsent) {
        return (
            <div className="cookie-box">
                <div className="cookie-box__section">
                    <div className='cookie-box__content'>
                        By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts.
                    </div>
                    <div className='cookie-box__controls'>
                        <button type="button" className="btn-primary mr-2" onClick={handleAccept}>
                            Reject All
                        </button>
                        <button type="button" className="btn-primary" onClick={handleAccept}>
                            Accept All Cookies
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}