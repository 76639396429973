import React, { useRef, useState } from "react";
import CountUp from "react-countup";
import Home1 from "../Assets/home1.png";
// import Video from "../Assets/video.mp4";
import PlayButton from "../Assets/playbutton.svg";
import polo from "../Assets/polo.png";
import circle from "../Assets/circle.png";
import circlecar1 from "../Assets/circlecar1.png";
// import orangecar from "../Assets/orangecar.png";
// import curveimage from "../Assets/curveimage.png";
import LED from "../Assets/LED.mp4";
// import curveimage from "../Assets/curveimage.png";



import "./Home.css";

const Home = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="home-wrapper">
      <div className="home-image-container">
        <img src={Home1} alt="home1" className="home-background-image" />

        <div className="home-content">
          <div className="home-left-content">
            <h1 className="home-heading">
              Let your brand become the talk of the town! It's time to hit the
              streets running.
            </h1>
            <h2 className="home-subheading">
              Reimagine Digital Advertising with Mobile Digital Out of Home
              (MDOOH)
            </h2>
            <p className="home-paragraph">
              <ul>
                <li>
                  <strong>Pioneering Platform:</strong> Introducing our unique
                  MDOOH advertising technology.
                </li>
                <li>
                  {" "}
                  <strong>Attention-Grabbing:</strong> High-traffic area screens
                  ensure ads are eye-catching and engaging.
                </li>
                <li>
                  <strong>Creative Flexibility:</strong> Digital formats enable
                  creative experimentation for memorable content.{" "}
                </li>
                <li>
                  <strong>Seamless Integration:</strong> Place your brand
                  naturally in consumer environments without being intrusive.{" "}
                </li>
                <li>
                  <strong>Data-Driven Impact:</strong> Leverage high-resolution
                  LED screens on buses, cars, and vehicles for dynamic,
                  location-based storytelling.{" "}
                </li>
                <li>
                  {" "}
                  <strong>Game-Changing Results:</strong> Our agile systems and
                  analytics drive effective, impactful advertising.
                </li>
              </ul>
            </p>
          </div>

          <div className="home-right-content">
            <div className="video-container">
              <video
                ref={videoRef}
                className="home-video"
                type="video/mp4"
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                controls // Keep video controls enabled
              >
                <source src={LED} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!isPlaying && (
                <img
                  src={PlayButton}
                  alt="Play"
                  className="play-button"
                  onClick={handlePlayPause} // Clicking the image will play the video
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="stats-container">
        <div className="stat-item">
          <h1>
            <CountUp start={0} end={5} duration={3} />
          </h1>
          <p>Total Clients</p>
        </div>

        <div className="stat-item">
          <h1>
            <CountUp start={0} end={27583} duration={3} />
          </h1>
          <p>Total Ads Displayed</p>
        </div>

        <div className="stat-item">
          <h1>
            <CountUp start={0} end={76} duration={3} />
          </h1>
          <p>Total Display Hours</p>
        </div>

        <div className="stat-item">
          <h1>
            <CountUp start={0} end={15217} duration={3} />
          </h1>
          <p>Total Area Covered:(KMs)</p>
        </div>
      </div>

      <div class="container">
        <img src={polo} alt="Blue Car" class="polo" />
        <div class="content">
          <h2>
            Unlocking new DOOH Advertising Possibilities; Beyond Billboards
          </h2>
          <ul class="bullet-points">
            <li>
              <b>Elevate Your Brand: </b>Capture attention with innovative
              content and cutting-edge graphics. Help you reach your target
              audience 10x faster
            </li>
            <li>
              <b>Act Fast: </b>Don’t let competitors seize the moment—contact us
              now! Add that ‘wow’ feeling to your products/services.
            </li>
            <li>
              <b>Maximize Reach: </b>Our smart DOOH solution targets consumers
              during their daily commutes.
            </li>
            <li>
              <b>Strategic Placement: </b>Roof-mounted digital signage targets
              pedestrians and street traffic. Leverage your outdoor ads with our
              attention-grabbing platform! Our signages are highly visible and
              adblock-proof making them unskippable.
            </li>
            <li>
              <b>Location-Based Ads:</b> Tailor messages for contextual
              relevance, e.g., snacks for movie-goers or sportswear for gym
              users.
            </li>
            <li>
              <b>Lasting Impact:</b> Create captivating brand stories that
              resonate long after the audience moves on.
            </li>
          </ul>
        </div>
      </div>

      <div class="circle-container">
        <div class="circle">
          <img src={circle} alt="Background Circle" class="circle-image" />
          <img src={circlecar1} alt="Car" class="car-image" />
        </div>
        <div class="cards-container">
          <div class="card" data-name="Card 1">
            <b>REGISTER</b>Sign-up with Adeiaz today and add wheels to your
            brand!!
          </div>
          <div class="card" data-name="Card 2">
            <b>PERSONALIZE</b>Choose the location and the time slot based on
            your preferences.
          </div>
          <div class="card" data-name="Card 3">
            <b>ENHANCE(DISPLAY & VISIBILITY)</b>Integrate seamlessly. Reach
            consumers organically, without disruption.
          </div>
          <div class="card" data-name="Card 4">
            <b>ENGAGE</b>Optimize your brand visibility strategy through our
            democratized data and analytics. Engage further with scannable
            technologies.
          </div>
          <div class="card" data-name="Card 5">
            <b>TRACK</b>Choose the location and the time slot based on your
            preferences.
          </div>
        </div>
      </div>

      {/* 14-10-2024- Commented */} 
      {/* <div class="car-section">
        <img src={orangecar} alt="Car" class="orangecar-image" />
      </div> */}
      {/* <div className="dooh-section">
        <h1 className="dooh-heading">Sky Is the Limit!</h1>
        <p>
          The global Digital Out-Of-Home (DOOH) advertising market size was
          valued at $24.32 billion in 2023 and is projected to reach $74.23
          billion by 2032, growing at a compound annual growth rate (CAGR) of
          13.20% during the forecast period ¹. Here are some key highlights of
          the DOOH marketing spend worldwide:
        </p>
        <ul>
          <li>
            <strong>Global ad spending:</strong> Expected to hit $18.64 billion
            by 2024
          </li>
          <li>
            <strong>Growth rate:</strong> Anticipated to grow at an annual rate
            of 5.18% from 2024-2029
          </li>
          <li>
            <strong>Market volume:</strong> Expected to reach $24 billion by
            2029
          </li>
          <li>
            <strong>Average DOOH spending per capita:</strong> Projected to be
            $2.41 in 2024
          </li>
          <li>
            <strong>Leading country:</strong> The United States is set to lead
            with a projected market volume of $5.277 million in 2024.
          </li>
        </ul>
       
      </div> */}
    </div>
  );
};

export default Home;
