import React, {} from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './index.css'

import GlobalHeader from "./Components/Pages/GlobalHeader"
import GlobalIcons from "./Components/Pages/GlobalIcons"
import GlobalFooter from "./Components/Pages/GlobalFooter"
import Dashboard from "./Components/Pages/Dashboard"
import Home from "./Components/Pages/Home"
import About from "./Components/Pages/About"
import Advertisers from "./Components/Pages/Advertisers"
import Contact from "./Components/Pages/Contact"
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy"
import News from "./Components/Pages/News"
import Careers from "./Components/Pages/Careers"
import CookieBox from "./Components/Global/CookieBox.jsx";


function App() {
  return (
    <Router>
      <GlobalHeader />
      <GlobalIcons />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/advertisers" element={<Advertisers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/news" element={<News />} />
          <Route path="/careers" element={<Careers />} />



        </Routes>
      </div>
      <GlobalFooter />
      <CookieBox/>
    </Router>
  );
}

export default App;
