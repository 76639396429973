import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./GlobalFooter.css";
// import Logo from "../Assets/footerlogo.png"; 
import Logo from "../Assets/headerlogo.png"

const GlobalFooter = () => {
  return (
    <footer className="global-footer">
      <div className="footer-sections">
        <div className="footer-section section-1">
          <img src={Logo} alt="Footer Logo" className="footer-logo" />
          <p>Unleash Dynamic Ads on the Move!</p>
        </div>

        <div className="footer-section section-2">
          <h3 className="contact-heading1">CONTACT</h3>
          <a
  href="https://www.google.com/maps/place/Dhi+Innovations/@12.8858509,77.594443,17z/data=!4m6!3m5!1s0x3bae152fd1dbd661:0xb4e926a02cb3fbc9!8m2!3d12.8858457!4d77.5970179!16s%2Fg%2F1hm5tqfxc?entry=ttu&g_ep=EgoyMDI0MTAwOC4wIKXMDSoASAFQAw%3D%3D"
  target="_blank"
  rel="noopener noreferrer"
  className="address-link1"
>
  <p className="address-para">
    <FaMapMarkerAlt className="address-icon" /> DigiAdeiaz Media Pvt. Ltd, #630,
    DHI Innovation Park, Arekere, Bannerghatta Road, Bangalore, Karnataka -
    560076
  </p>
</a>

          <p className="phone-para">
            <FaPhoneAlt className="icon" /> +917338562232
          </p>
          <p className="mail-para">
            <FaEnvelope className="icon" /> info@adeiaz.com
          </p>
        </div>

        <div className="footer-section section-3">
          <h3>QUICK LINKS</h3>
          <Link to="/about" className="footer-link">
            About Us
          </Link>
          <Link to="/advertisers" className="footer-link">
            Advertisers
          </Link>
         
          <Link to="/news" className="footer-link">
            News & Media
          </Link>
          <Link to="/careers" className="footer-link">
            Careers
          </Link>
          <Link to="/contact" className="footer-link">
            Contact
          </Link>
        </div>
        

        <div className="footer-section section-4">
          <h3 className="hour">OFFICE TIMINGS:</h3>
          <p>9:30 am to 5:30 pm</p>
        </div>
      </div>

      <div className="footer-divider"></div>

      <div className="footer-copyright">
        <p> Copyright &copy; 2024 Adeiaz
        </p>
      </div>

      <div className="footer-copyright1">
        <p>Powered by DND INFOTECH LLP
        </p>
      </div>

      <div class="footer-policy">
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                <p>Privacy Policy</p>
            </a>
        </div>
    </footer>
  );
};

export default GlobalFooter;
